import { ref, computed } from "vue";

export function useButtons(logicFormComponent) {
  const disabledActions = ref(false);
  const onContinueForm = async () => {
    disabledActions.value = true;
    const response = await logicFormComponent.value.onContinue();
    disabledActions.value = false;
    return response;
  };
  const onDeleteForm = async () => {
    disabledActions.value = true;
    const response = await logicFormComponent.value.onDelete();
    disabledActions.value = false;
    return response;
  };
  const validateForm = () => {
    return logicFormComponent.value.validateForm();
  };
  const itShowsModalConfirmation = computed(() => {
    return logicFormComponent.value.itShowsModalConfirmation;
  });
  const itShowsModalConfirmationDelete = computed(() => {
    return logicFormComponent.value.itShowsModalConfirmationDelete;
  });

  return {
    onContinueForm,
    onDeleteForm,
    validateForm,
    disabledActions,
    itShowsModalConfirmation,
    itShowsModalConfirmationDelete,
  };
}

export function transformFormForMobile(form, isMobile) {
  if (!isMobile) return form;

  if (!form.template) return form;

  if (typeof form.template === "function") {
    const template = context => {
      const processedTemplate = form.template(context);
      return processedTemplate.map(group => ({
        ...group,
        templateColumns: "1fr",
        templateRows: "auto",
        templateAreas: undefined,
      }));
    };
    return {
      ...form,
      template,
    };
  } else {
    return {
      ...form,
      template: form.template.map(group => ({
        ...group,
        templateColumns: "1fr",
        templateRows: "auto",
        templateAreas: undefined,
      })),
    };
  }
}
